import React, { useState, useEffect } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import { Redirect } from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import Iframe from "react-iframe";
import SelectClass from "../ChildComponent/SelectClass";

const StudentDueList = (props) => {
  const token = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(token !== null);
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [iframeHeight, setIframeHeight] = useState("800px");
  const [fileURL, setFileURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileDirectory, setFileDirectory] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [selectedClassPosition, setSelectedClassPosition] = useState(-1);
  const [printableSamples, setPrintableSamples] = useState([]);
  const [selectedSampleId, setSelectedSampleId] = useState("");
  const [selectedSamplePosition, setSelectedSamplePosition] = useState(-1);
  const [classSectionName, setClassSectionName] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setDocumentType(props.match.params.document_type);
  }, [props.match.params.document_type]);

  useEffect(() => {
    if (printableSamples.length === 0) {
      getPrintableSamples();
    } else {
      setDocumentFileURL();
    }
  }, [selectedClassPosition]);

  useEffect(() => {
    if (selectedClassPosition >= 0) {
      setDocumentFileURL();
    }
  }, [selectedSamplePosition]);

  const callBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name
  ) => {
    setClassId(class_id);
    setSectionId(section_id);
    setSessionId(session_id);
    setSelectedClassPosition(selected_position);
    //alert("call" + class_id);

    const classSection =
      section_id === "No" ? class_name : `${class_name}:${section_name}`;
    setClassSectionName(classSection);
  };

  const getPrintableSamples = async () => {
    setLoading(true);

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getPrintableSample.php?";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    const params = {
      school_code: SCHOOL_CODE,
      document_type: documentType,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    try {
      const response = await axios.get(URL, {
        params: params,
      });

      const data = response.data;
      setLoading(false);

      if (data.api_status === "OK") {
        //alert(JSON.stringify(data.data));
        setPrintableSamples(data.data);
        setSample(data.data);
      } else {
        myToast.notify_warning(data.api_remark);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching printable samples:", error);
    }
  };

  const setSample = (samples) => {
    const sample = samples[0];
    setFileName(sample.file_name);
    setFileDirectory(sample.file_directory);
    setSelectedSampleId(sample.sample_id);
    setSelectedSamplePosition(0);
  };

  const setDocumentFileURL = () => {
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const url =
      process.env.REACT_APP_SERVER_NAME +
      fileDirectory +
      fileName +
      `?&class_id=${classId}&section_id=${sectionId}&class_section_name=${classSectionName}&session_id=${sessionId}&sample_id=${selectedSampleId}&system_code=${process.env.REACT_APP_SYSTEM_CODE}&school_code=${SCHOOL_CODE}`;

    console.log("FILE_URL", url);
    setFileURL(url);
    setShowPreview(true);
  };

  const handleIframeLoad = () => {
    setShowPreview(false);
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <div className="content">
      <div
        className="content-header"
        style={{ background: COLORS.primary_color_shade5 }}
      >
        <div className="leftside-content-header">
          <ul className="breadcrumbs">
            <li>
              <i
                className="fa fa-home"
                aria-hidden="true"
                style={{ color: COLORS.white }}
              />
              <a href="#">Report Management </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="animated fadeInUpn bg_page_white_rounded box_shadow"
        style={{ "--mMargin": "50px" }}
      >
        {loading && <MyLoader />}
        <SelectClass
          action={callBackClassSelect}
          selected_position={selectedClassPosition}
        />

        <div className="row" style={{ margin: "0px 10px 0px 10px" }}>
          {showPreview ? (
            <div>
              <MyLoader />
            </div>
          ) : (
            ""
          )}
          <Iframe
            url={fileURL}
            width={showPreview ? "0%" : "100%"}
            height={showPreview ? "0px" : "800px"}
            overflow="auto"
            id="iFrame1"
            className=""
            display="initial"
            position="relative"
            onLoad={() => {
              setShowPreview(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentDueList;
