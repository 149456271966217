import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import Iframe from "react-iframe";
import MdlSelectMultipleStudent from "../dialog/MdlSelectMultipleStudent";
import MdlStudentIdCardSettings from "../dialog/MdlStudentIdCardSettings";
export default class PrintableDocsIdCard extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      isOpenSelectStudent: false,
      isOpenSettings: false,
      showStudentIdCard: false,
      Total: 0,
      mSelectedStudent: [],
      mPrintableSamples: [],
      mColorOption: [],
      iFrameHeight: "0px",
      SELECTED_SAMPLE_ID: "",
      SELECTED_SAMPLE_POSITION: 0,
      SELECTED_COLOR_POSITION: 0,
      SELECTION_TYPE: "",
      FILE_URL: "",
      FILE_NAME: "",
      FILE_DIRECTORY: "",
      DOCUMENT_TYPE: "",
      COLOR_OPTION_ID: "",
    };
    this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
    this.HandleCloseSelectStudent = this.HandleCloseSelectStudent.bind(this);
  }
  HandleCloseSelectStudent = () => {
    this.setState({
      isOpenSelectStudent: false,
    });
  };

  HandleSelectedStudent = (mSelectedStudent) => {
    //var mSelectedStudent=JSON.parse(localStorage.getItem("SelectedMultipleStudent"));
    if (mSelectedStudent == null) {
      mSelectedStudent = [];
    }
    this.state.isOpenSelectStudent = false;
    this.state.showStudentIdCard = true;
    this.state.mSelectedStudent = mSelectedStudent;
    this.state.SELECTION_TYPE = "student";

    this.setFileUrL();
  };
  SelectStudent = () => {
    this.setState({
      isOpenSelectStudent: true,
    });
  };
  OpenSettings = () => {
    this.setState({
      isOpenSettings: true,
    });
  };

  componentDidMount() {
    this.state.DOCUMENT_TYPE = this.props.match.params.document_type;

    this.getPrintableSamples();
  }
  getPrintableSamples() {
    this.setState({
      Loading: true,
    });

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getPrintableSample.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;
    // alert(URL);
    console.log("URL>>>>>" + URL);

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          document_type: this.state.DOCUMENT_TYPE,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        // console.log("Response2>>>>>"+response);
        let api_status = response.api_status;
        //alert(JSON.stringify(response));

        if (api_status == "OK") {
          this.setState({
            mPrintableSamples: response.data,
          });
          //alert("mPrintableSamples>>>>>"+JSON.stringify(this.state.mPrintableSamples));
          this.setSample(this.state.SELECTED_SAMPLE_POSITION);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  setSample(selected_sample_position) {
    var sample_id =
      this.state.mPrintableSamples[selected_sample_position].sample_id;
    this.state.FILE_NAME =
      this.state.mPrintableSamples[selected_sample_position].file_name;
    this.state.FILE_DIRECTORY =
      this.state.mPrintableSamples[selected_sample_position].file_directory;
    this.state.SELECTED_SAMPLE_ID = sample_id;
    this.getColorOptions(selected_sample_position);
  }
  getColorOptions(selected_sample_position) {
    this.state.mColorOption = [];
    this.state.mColorOption =
      this.state.mPrintableSamples[selected_sample_position].color_options;
    this.setColorOptions(this.state.SELECTED_COLOR_POSITION);
  }
  setColorOptions(selected_position_color_option) {
    if (this.state.mColorOption.length == 0) {
      this.state.COLOR_OPTION_ID = "";
    } else {
      this.state.COLOR_OPTION_ID =
        this.state.mColorOption[selected_position_color_option].color_option_id;
    }

    if (this.state.mSelectedStudent.length != 0) {
      this.setFileUrL();
    }
    this.setState({});
  }
  setFileUrL() {
    var class_id = "";
    var section_id = "";
    var session_id = "";
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    var mStudentIds = [];

    if (this.state.SELECTION_TYPE === "student") {
      for (let index = 0; index < this.state.mSelectedStudent.length; index++) {
        const element = this.state.mSelectedStudent[index];
        var student_id = element.student_enrollment_no;
        session_id = element.session_id;
        mStudentIds[index] = student_id;
      }
      //alert(JSON.stringify(mStudentIds));
    }
    this.state.FILE_URL =
      process.env.REACT_APP_SERVER_NAME +
      this.state.FILE_DIRECTORY +
      this.state.FILE_NAME +
      "?&student_ids=" +
      JSON.stringify(mStudentIds) +
      "&class_id=" +
      class_id +
      "&section_id=" +
      section_id +
      "&session_id=" +
      session_id +
      "&sample_id=" +
      this.state.SELECTED_SAMPLE_ID +
      "&selection_type=" +
      this.state.SELECTION_TYPE +
      "&system_code=" +
      process.env.REACT_APP_SYSTEM_CODE +
      "&color_option_id=" +
      this.state.COLOR_OPTION_ID +
      "&school_code=" +
      SCHOOL_CODE;
    console.log("FILE_URL", this.state.FILE_URL);
    // alert(this.state.FILE_URL);
    this.setState({});
  }
  changeColor = (index) => {
    this.setState(
      {
        SELECTED_COLOR_POSITION: index,
        showStudentIdCard: true,
      },
      () => {
        this.setColorOptions(this.state.SELECTED_COLOR_POSITION);
      }
    );
  };
  changeSample(position) {
    this.setState(
      {
        SELECTED_SAMPLE_POSITION: position,
      },
      () => {
        // alert(this.state.SELECTED_SAMPLE_POSITION);
        this.setSample(this.state.SELECTED_SAMPLE_POSITION);
      }
    );
  }
  autoResize = () => {
    alert("loaded");
    var iFrame = document.getElementById("iFrame1");
  };

  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path={"/AdminMain/PrintableDocsIdCard/:document_type"}
              exact
              render={() => {
                return (
                  <div className="content">
                    <div
                      className="content-header"
                      style={{ background: COLORS.primary_color_shade5 }}
                    >
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i
                              className="fa fa-home"
                              aria-hidden="true"
                              style={{ color: COLORS.white }}
                            />
                            <a href="#">
                              {this.state.mPrintableSamples.length != 0
                                ? "Printable-" +
                                  this.state.mPrintableSamples[0]
                                    .document_caption
                                : "Printable"}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="animated fadeInUpn bg_page_grey_rounded box_shadow"
                      style={{ "--mMargin": "50px" }}
                    >
                      <div className="row">
                        <div className="col-sm-4 col-lg-4 padding_0">
                          <div
                            className=" bg_card_primary_outline gravity_center_vertical"
                            style={{
                              padding: "5px",
                              color: "#fff",
                              margin: "0px 5px 0px 0px",
                            }}
                          >
                            <div
                              className="bg_card_primary gravity_center width_match_parent margin_5 padding_5"
                              onClick={this.SelectStudent}
                            >
                              <h5 className="arrow">
                                {" "}
                                Select Students Manually
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 col-lg-4 padding_0 ">
                          <div
                            className="bg_card_primary_outline  "
                            style={{
                              padding: "5px",
                              color: "#fff",
                              margin: "0px 5px 0px 0px",
                            }}
                          >
                            <div
                              className="bg_card_primary gravity_center width_match_parent margin_5 padding_5"
                              onClick={this.SelectStudent}
                            >
                              <h5 className="arrow"> Select Class</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 col-lg-4 padding_0 ">
                          <div
                            className="bg_card_primary_outline  "
                            style={{
                              padding: "5px",
                              color: "#fff",
                              margin: "0px 5px 0px 0px",
                            }}
                          >
                            <div
                              className="bg_card_primary gravity_center width_match_parent margin_5 padding_5"
                              onClick={this.OpenSettings}
                            >
                              <h5 className="arrow">IdCard Settings</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-lg-6 padding_0">
                          <div
                            className=""
                            style={{
                              padding: "5px",
                              color: "#000",
                              margin: "0px 5px 0px 0px",
                            }}
                          >
                            <h5 className="">Sample List</h5>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-6 padding_0 "></div>
                      </div>

                      <div
                        className="row bg_card_grey_outline"
                        style={{ height: "140px" }}
                      >
                        {this.state.mPrintableSamples &&
                          this.state.mPrintableSamples.map((item, index) => (
                            <div className="col-sm-2 col-lg-2 padding_0 height_match_parent">
                              <div
                                className={
                                  this.state.SELECTED_SAMPLE_POSITION == index
                                    ? "bg_card_green_outline cursor"
                                    : "bg_card_grey_outline cursor"
                                }
                                style={{
                                  padding: "1px",
                                  color: "#fff",
                                  margin: "5px 10px 0px 10px",
                                  height: "110px",
                                }}
                                onClick={(e) => this.changeSample(index)}
                              >
                                <div className="gravity_center img_sample_docs">
                                  <img src={item.sample_img} class="" />
                                </div>
                              </div>
                              <div className="gravity_center">
                                {item.sample_name}
                              </div>
                            </div>
                          ))}
                      </div>

                      <div
                        className="row"
                        style={{ margin: "0px 10px 0px 10px" }}
                      >
                        <h5 className="">Color Option</h5>
                      </div>
                      <div
                        className="row bg_card_grey_outline"
                        style={{ height: "auto" }}
                      >
                        {this.state.mColorOption &&
                          this.state.mColorOption.map((item, index) => {
                            let strColor = item.sample_color_code;
                            var ArrColor = strColor.split("_");
                            var color1 = ArrColor[0];
                            var color2 = ArrColor[1];
                            return (
                              <div className="col-sm-1 col-lg-1 padding_0 height_match_parent">
                                <div
                                  className={
                                    this.state.SELECTED_COLOR_POSITION == index
                                      ? "bg_card_green_outline cursor"
                                      : "bg_card_grey_outline cursor"
                                  }
                                  style={{
                                    padding: "3px",
                                    color: "#fff",
                                    margin: "2px",
                                    height: "50px",
                                  }}
                                  onClick={(e) => this.changeColor(index)}
                                >
                                  <div className="gravity_center_row">
                                    <div
                                      style={{
                                        backgroundColor: color1,
                                        height: "40px",
                                        width: "50%",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        backgroundColor: color2,
                                        height: "40px",
                                        width: "50%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="content_center">
                                  {item.sample_color_name}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        className="row"
                        style={{ margin: "0px 10px 0px 10px" }}
                      >
                        <h5 className="">Preview</h5>
                      </div>

                      <div
                        className="row"
                        style={{ margin: "0px 10px 0px 10px" }}
                      >
                        {this.state.showStudentIdCard ? (
                          <div>
                            <MyLoader />
                          </div>
                        ) : (
                          ""
                        )}
                        <Iframe
                          url={this.state.FILE_URL}
                          width="100%"
                          height="800px"
                          overflow="visible"
                          id="iFrameStudentId"
                          className="bg_card_primary_outline"
                          display="initial"
                          position="relative"
                          onLoad={() => {
                            this.setState({
                              showStudentIdCard: false,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {this.state.isOpenSelectStudent === true ? (
                      <MdlSelectMultipleStudent
                        actionClose={this.HandleCloseSelectStudent}
                        actionHandleSelected={this.HandleSelectedStudent}
                      ></MdlSelectMultipleStudent>
                    ) : (
                      ""
                    )}

                    {this.state.isOpenSettings === true ? (
                      <MdlStudentIdCardSettings
                        sample_id={this.state.SELECTED_SAMPLE_ID}
                        actionClose={this.HandleCloseSettings}
                        actionHandleSelected={this.HandleSelectedSettings}
                      ></MdlStudentIdCardSettings>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }}
            />
          </switch>
        </Router>
      );
    }
  }
}
